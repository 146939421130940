import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Hyperbeam from "./screens/Hyperbeam";
import ReactGA from "react-ga";
import { hotjar } from "react-hotjar";
import { useEffect } from "react";
import Privacy from "./screens/Privacy";
import Terms from "./screens/Terms";

const trackingCode = "UA-222878894-1";

function App() {
  ReactGA.initialize(trackingCode);
  ReactGA.pageview(window.location.pathname);

  useEffect(() => {
    hotjar.initialize(2875151, 6);
  }, []);

  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            <Page>
              <Hyperbeam />
            </Page>
          )}
        />
        <Route
          exact
          path="/privacy"
          render={() => (
            <Page>
              <Privacy />
            </Page>
          )}
        />
        <Route
          exact
          path="/terms"
          render={() => (
            <Page>
              <Terms />
            </Page>
          )}
        />
        
      </Switch>
    </Router>
  );
}

export default App;
