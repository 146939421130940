import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Hero.module.sass";

const Privacy = ({ scrollToRef }) => {
  const iframeRef = React.createRef();
  const [iframeHeight, setIframeHeight] = React.useState('100%');

  React.useEffect(() => {
    setIframeHeight(`${iframeRef.current.contentWindow.document.documentElement.scrollHeight}px`);
  }, []);

  return (
    <div className={styles.hero}>
        <iframe 
          src="https://app.termly.io/document/privacy-policy/00f8e874-02f9-48c3-86e5-38fdc71622f0" 
          frameBorder='0'
          sandbox='allow-scripts allow-modal allow-same-origin'
          ref={iframeRef}
          id="widget"
          name="widget"
          title="widget"
          scrolling="no"
          width="100%"
          height={iframeHeight}
          style={{ position: "relative", height: iframeHeight }}/>
          
    </div>
  );
};

export default Privacy;
