import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Hero.module.sass";
import Image from "../../../components/Image";
import ScrollButton from "../../../components/ScrollButton";
import ScrollParallax from "../../../components/ScrollParallax";
import Subscription from "../../../components/Subscription";

const Hero = ({ scrollToRef }) => {
  return (
    <div className={styles.hero}>
      <div className={cn("container", styles.container)}>
        <ScrollParallax className={styles.wrap}>
          <div className={cn("stage", styles.stage)}>
          AI Platform for Complex Chronic Illnesses
          </div>
          <h1 className={cn("h1", styles.title)}>
          AI tools made for modern providers
          </h1>
          <div className={styles.text}>
          Hyperbeam is an RPM platform analyzing novel health signals to radically improve patient outcomes. We're heads-down building and piloting Alpaca Health, partnering with world-class clinicians.

            {/*<a style={{ fontWeight: 550 }}>
            partnering with world-class clinicians.  
  </a>*/}
            {" "} 
          </div>
          <div className={styles.btns}>
            <Subscription
              className={styles.subscription}
              placeholder="Learn More &#38; Get Early Access"
            />
          </div>
        </ScrollParallax>
        <div className={styles.gallery}>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              srcSet="/images/content/hyperbeam_hero.png"
              srcSetDark="/images/content/hyperbeam_hero.png"
              src="/images/content/hyperbeam_hero.png"
              srcDark="/images/content/hyperbeam_hero.png"
              alt="App"
            />
          </ScrollParallax>
        </div>
      </div>
    </div>
  );
};

export default Hero;
