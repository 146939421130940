import React, { useState } from "react";
import cn from "classnames";
import styles from "./Subscription.module.sass";
import Icon from "../Icon";
import axios from "axios";
import ReactGA from "react-ga";
import { Popup } from 'react-typeform-embed';
import { Link } from "react-router-dom";

const Subscription = ({ className, placeholder }) => {
  const [email, setEmail] = useState("");

  const handleSubmit = async e => {
    e.preventDefault();

    ReactGA.event({
      category: "User Actions",
      action: "Clicked Subscribe Button",
      label: "Email Signup"
    });

    try {
      const payload = {
        email_address: email,
        status: "subscribed"
      };

      const { data } = await axios.post(
        `/.netlify/functions/add-email-subscribers`,
        payload
      );
      alert("Contact details were added successfully");

      window.location.href = "https://1tjrtex6f99.typeform.com/to/iJKyi3SK";
    } catch (error) {
      alert(error.message);
      window.location.href = "https://1tjrtex6f99.typeform.com/to/iJKyi3SK";
    }
  };

  return (
        <a
          href="https://www.joinalpaca.com/"
          target="_blank"
        >
          <button
              className={cn("button", styles.button)}
            >
              <span>Learn More</span>
              <Icon name="arrow-right" size="10" />
          </button>
        </a>
  );
};

export default Subscription;
